import React from "react";
import styled from "../lib/styled";
import { Column } from "hedron";
import { presets } from "../lib/presets";

export const ContentColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  ${presets.mq.min.tablet} {
    padding-right: 0;
  }
  ${presets.mq.max.phablet} {
    padding: 20px 0;
  }
`;

export const NewsColumn = styled(Column)`
  height: 100%;
  ${presets.mq.min.tablet} {
    padding-left: 0;
  }
  ${presets.mq.max.phablet} {
    padding: 20px 0;
  }
`;
