import { CommentCount } from "disqus-react";
import moment from "moment";
import * as React from "react";
import { presets } from "../lib/presets";
import styled from "../lib/styled";
import DashSeparator from "./DashSeparator";
import { IAuthor } from "../types/wordpressPost";
import { GatsbyImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  display: flex;
  font-family: "Keep Calm";
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 11px;
  color: ${(props) => props.theme.color.darkOne};
  margin-bottom: 20px;

  ${presets.mq.max.tablet} {
    flex-direction: column;
  }

  .disqus-comment-count {
    white-space: nowrap;
  }
`;

const Date = styled.div``;

const AuthorMetaWrapper = styled.a`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.darkOne};

  ${presets.mq.max.tablet} {
    padding-bottom: 15px;
  }
`;
const ArticleMetaWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorAvatar = styled(GatsbyImage)`
  height: 40px;
  width: 40px;
  border-radius: 100%;
  img, picture {
    border-radius: 100%;
  }
  
  border: 2px solid ${(props) => props.theme.color.accent};
  background-color: ${(props) => props.theme.color.midGrey};
  margin: 0;
  margin-right: 10px;
  object-fit: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Author = styled.div`
  margin-right: 40px;
`;

const AuthorText = styled.div`
  font-family: "Keep Calm Light";
`;

interface IArticleMetaProps {
  author: IAuthor;
  id: string;
  slug: string;
  title: string;
  date: string;
}

const ArticleMeta = (props: IArticleMetaProps) => {
  const disqusShortname = "jurassic-outpost";
  const disqusConfig = {
    url: `https://jurassicoutpost.com/${props.slug}`,
    identifier: props.id,
    title: props.title,
  };

  return (
    <Wrapper>
      <AuthorMetaWrapper
        target="_blank"
        href={props.author.url ? props.author.url : ""}
      >
        {props.author.customAvatar && (
          <AuthorAvatar
            alt={props.author.name}
            image={props.author.customAvatar.childImageSharp.gatsbyImageData}
          />
        )}
        <Author>
          <div>Author</div>
          <AuthorText>{props.author.name}</AuthorText>
        </Author>
      </AuthorMetaWrapper>
      <ArticleMetaWrapper>
        <Date>{moment(props.date).format("Do MMMM YYYY hh:mmA")}</Date>
        <DashSeparator />
        <CommentCount shortname={disqusShortname} config={disqusConfig} />
      </ArticleMetaWrapper>
    </Wrapper>
  );
};

export default ArticleMeta;
