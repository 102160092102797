import * as React from "react";
import styled from "../lib/styled";

const Wrapper = styled.div`
  display: inline-flex;
  width: 14px;
  justify-content: space-between;
  margin: 3px 20px;
`;
const Dash = styled.div`
  height: 12px;
  width: 2px;
  transform: rotate(20deg);
  background: ${props => props.theme.color.accent};
`;

const DashSeparator = () => {
  return (
    <Wrapper>
      <Dash />
      <Dash />
      <Dash />
    </Wrapper>
  );
};

export default DashSeparator;
