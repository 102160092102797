export const getExcerpt = (article: any) => {
  if (article.excerpt) {
    return article.excerpt;
  }
  let trimmedContent = article.content.replace(/(<([^>]+)>)/gi, "");
  const maxLength = 400;
  if (trimmedContent.length > maxLength) {
    //trim the string to the maximum length
    trimmedContent = trimmedContent.substr(0, maxLength);

    //re-trim if we are in the middle of a word and
    trimmedContent = trimmedContent.substr(
      0,
      Math.min(trimmedContent.length, trimmedContent.lastIndexOf(" "))
    );
    trimmedContent += "...";
  }
  return "<p>" + trimmedContent + "</p>";
};
